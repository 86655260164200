import React, { useEffect, useState } from 'react'
import DashboardHeader from "../common/DashboardHeader";
import AdminMenu from "../common/AdminMenu";
import DataTable from 'react-data-table-component';
import { NavLink } from 'react-router-dom';
import { IMAGE } from '../../../utils/Theme';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Accordion from 'react-bootstrap/Accordion';
import Loader from '../../../utils/Loader';
import ApiConnection, { BaseUrl } from '../../../utils/ApiConnection';


const data = [
    {
        game:"RajasDel1500",
        match:"Raj Vs. Del ", 
        date:"08.28.2023",
        teams:"20",
        wpaid:"$2000",
        commision:"$2000",
        earniong:"$2000",
        list:[
            {
                image:IMAGE.cricketImg,
                name:"RajasDel1500",
                team:"T!",
                credits:"25"
            },
            {
                image:IMAGE.cricketImg,
                name:"RajasDel1500",
                team:"T!",
                credits:"25"
            },
            {
                image:IMAGE.cricketImg,
                name:"RajasDel1500",
                team:"T!",
                credits:"25"
            },
        ]
        
    },
    {
        game:"RajasDel1500",
        match:"Raj Vs. Del ", 
        date:"08.28.2023",
        teams:"20",
        wpaid:"$2000",
        commision:"$2000",
        earniong:"$2000",
        list:[
            {
                image:IMAGE.cricketImg,
                name:"RajasDel1500",
                team:"T!",
                credits:"25"
            },
            {
                image:IMAGE.cricketImg,
                name:"RajasDel1500",
                team:"T!",
                credits:"25"
            },
            {
                image:IMAGE.cricketImg,
                name:"RajasDel1500",
                team:"T!",
                credits:"25"
            },
        ]
        
    },
    {
        game:"RajasDel1500",
        match:"Raj Vs. Del ", 
        date:"08.28.2023",
        teams:"20",
        wpaid:"$2000",
        commision:"$2000",
        earniong:"$2000",
        list:[
            {
                image:IMAGE.cricketImg,
                name:"RajasDel1500",
                team:"T!",
                credits:"25"
            },
            {
                image:IMAGE.cricketImg,
                name:"RajasDel1500",
                team:"T!",
                credits:"25"
            },
            {
                image:IMAGE.cricketImg,
                name:"RajasDel1500",
                team:"T!",
                credits:"25"
            },
        ]
        
    },
]

const EarningHistory = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [show, setshow] = useState();
    const [loading, setloading] = useState(false)
    const [listdata, setlistdata] = useState([])

    const ShowHideHandle = (i) => {
        setshow(i)
    }

    const GetBank = async ()=>{
        setloading(true)
        try {
            const  response = await ApiConnection.get('earning-history')
            if(response?.status == 200){
                setloading(false)
                console.log(response.data?.data?.list)
                setlistdata(response.data?.data?.list)
            } else{
                setloading(false)
            }  
        } catch(err){
            setloading(false)
        }
    }
    useEffect(() => {
        GetBank()
    },[])



  return (
    <div>
           {loading && <Loader/>}
    <DashboardHeader title="Earning History" />
    <AdminMenu />
    <div className="container">
      <div className="dashboard-panel custom-table">

      <div className="withdraw-history-form row align-items-center mb-4">
                        <div className="col-auto">
                            <label>From Date:</label>
                        </div>
                        <div className="col-lg-2">
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" />
                        </div>
                        <div className="col-auto">
                            <label>To Date:</label>
                        </div>
                        <div className="col-lg-2">
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" />
                        </div>
                        <div className="col-lg-2">
                            <button className="bluesolidBtn">Search</button>
                        </div>
                        <div className="col-lg-3">
                            <button className="csvBtn"><i className="fas fa-download"></i>  Download Excel</button>
                        </div>
                    </div>
      

<div className='accordian-table-container'>

    <ul className='admin-ern-table'>
            <li><b>Fantasy Game</b></li>
            <li><b>Match</b></li>
            <li><b>Match Date</b></li>
            <li><b>Teams Joined</b></li>
            <li><b>Winner Paid</b></li>
            <li><b>Commission</b></li>
            <li><b>Earning</b></li>
    </ul>

    {listdata&&listdata.map((item, index) =>{
        return ( 
            <>
            <ul key={index} className='admin-ern-table' onClick={()=>ShowHideHandle(index)}>
                <li>{item?.game_name}</li>
                <li>{item?.a_team_name} VS {item?.b_team_name}</li>
                <li>{item?.match_date}</li>
                <li>{item?.team_joined}</li>
                <li>{item?.winner_paid}</li>
                <li>{item?.commission}</li>
                <li>{item?.earning}</li>
            </ul>
            {show == index &&
            <>
           { item.game_winning_details.length > 0 ?
            <table className='admin-ern-table-inner-table'>
               <thead>
                    <th></th>
                    <th>Username</th>
                    <th>Team</th>
                    <th>Credit</th>
               </thead>
               <tbody>
               {item.game_winning_details.map((row, i)=>{
        return (
                <tr>
                    <td><img src={BaseUrl.baseurl + row?.image} width="50" /></td>
                    <td>{row?.display_name}</td>
                    <td>{row?.team_unique_id}</td>
                    <td>{row?.credit}</td>
                </tr>
            )
        })}
               </tbody>
            </table>
            :
            <h5>No game winning details</h5>
    }
            </>
    }
    
            </>
        )
    })}

 
</div>


      </div>
      </div>
        
    </div>
  )
}

export default EarningHistory