import React, { useEffect, useState } from 'react'
import DashboardHeader from "../common/DashboardHeader";
import AdminMenu from "../common/AdminMenu";
import { NavLink } from 'react-router-dom';
import ApiConnection from '../../../utils/ApiConnection';
import TableLoader from '../../../utils/TableLoader';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import Loader from '../../../utils/Loader';

const CmsPage = () => {

    const [search, setsearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const [loading, setloading] = useState(false)
    const [pageloading, setpageloading] = useState(false)
    const [statusmodal, setstatusmodal] = useState(false);
    const [deletemodal, setdeletemodal] = useState(false);
    const [status, setstatus] = useState('');
    const [rowId, setrowId] = useState('');
  
    const GetData = async ()=>{
      setloading(true)
      try {
          const  response = await ApiConnection.get('cms')
          if(response?.status == 200){
              setFilterResult(response?.data?.data?.data)
              console.log(response.data.data.data)
              setloading(false)
          } else{
              setloading(false)
          }  
      } catch(err){
          setloading(false)
      }
  }
  
  useEffect(()=>{
      GetData()
  },[])
  
  const SearchHandle = (value)=>{
      setsearch(value)  
  
      // const result = FilterResult && FilterResult.filter(data =>{
      //     return data?.club_name.toLowerCase().match(value.toLowerCase())
      //   })
      //   setFilterResult(result)
  }
  
  const StatusModal = (status, rid) =>{
      setstatus(status)
      setstatusmodal(true)
      setrowId(rid)
  }
  
  const StatusHandler = async () =>{
      setloading(true)
      setstatusmodal(false)
      try {
  
          let data = new FormData();
         data.append('status', status == 1 ? 0 : 1);
          data.append('_method', 'PUT');
  
          const  response = await ApiConnection.post(`cms/change-status/${rowId}`, data)
          if(response?.status == 200){
              GetData()
              setloading(false)
              setstatusmodal(false)
              toast.success(response?.data?.message)
          } else{
              setloading(false)
              setstatusmodal(false)
          }  
      } catch(err){
          setloading(false)
          setstatusmodal(false)
      }
  }
  
  const deleteModal = (rid) =>{
      setdeletemodal(true)
      setrowId(rid)
  }
  
  const DeleteHandler = async () =>{
      setloading(true)
      setdeletemodal(false)
      try {
  
          const  response = await ApiConnection.delete(`cms/${rowId}`)
          if(response?.status == 200){
              GetData()
              setloading(false)
              setdeletemodal(false)
              toast.success(response?.data?.message)
          } else{
              setloading(false)
              setdeletemodal(false)
              toast.error(response?.data?.message)
          }  
      } catch(err){
          setloading(false)
          setdeletemodal(false)
          toast.error(err.response?.data?.message)
      }
  }
  
  const  DownLoadExcel = async () =>{
    
      setpageloading(true)
  
      try{
          const  response = await ApiConnection.get('cms/excel')
          if(response?.status == 200){
              setpageloading(false)
              const link = document.getElementById("Download");
              link.setAttribute("href", response.data?.data?.path);
              link.click();
          } else{
              setpageloading(false)
              toast.error(response?.data?.message)
          } 
  
      } catch(err){
          setpageloading(false)
          toast.error(err?.response?.data?.message)
      }
  }



  return (
    <div>
    {pageloading && <Loader/>}
<DashboardHeader title="CMS list" />
<AdminMenu />
<div className="container">
<div className="dashboard-panel">

      <div className='table-top'>
           <div className='form-group w-25'>
              <input type='text' className='form-control' placeholder='Enter name'
              value={search}
              onChange={(e)=>SearchHandle(e.target.value)}
              />
          </div>
          <div className='form-group'>
              <ul>
                  <li>
                      <NavLink to="/admin/cms/add" className='btn  btn-success' ><i className="fa-solid fa-plus"></i> Add CMS</NavLink>
                  </li>
                  <li>
                      <button className='btn btn-outline-info' onClick={DownLoadExcel}><i className="fa-solid fa-file-excel"></i> Export </button>
                  </li>
              </ul>
              <a id="Download" download></a>
          </div>
      </div>
      <div className='custom-table table-responsive'>
{!loading ? 

<table className='table'>
<thead>
      <th>Page Name</th>
      <th>Page Title</th>
      <th>Slug</th>
      <th>Parent Page</th>
      {/* <th>Description</th> */}
      <th>Action</th>
      </thead>
  <tbody>
      {FilterResult&&FilterResult.length > 0 ? 
     FilterResult&&FilterResult.map((list, index)=>{
      return (
          <tr key={index}>
            <td>{list?.page_name}</td>
          <td>{list?.page_title}</td>
          <td>{list?.page_slug}</td>
          <td>{list?.parent?.page_name}</td>
          {/* <td><div dangerouslySetInnerHTML={{__html: list?.description}}></div></td> */}
          <td>
              {/* <NavLink to={`/admin/cms/view/${list?.id}`} className="btn btn-sm btn-outline-primary"><i className="fa-solid fa-eye"></i></NavLink> */}
              <NavLink to={`/admin/cms/edit/${list?.id}`} className="btn btn-sm btn-outline-warning ml-2"><i className="fa-solid fa-pen-to-square"></i></NavLink>
              <button onClick={()=>deleteModal(list?.id)} className='btn btn-sm btn-outline-danger ml-2'><i className="fa-solid fa-trash"></i></button>
              <button onClick={()=>StatusModal(list?.is_active, list?.id)} className={list?.is_active == 1 ? 'btn btn-sm btn-outline-success ml-2' : 'btn btn-sm btn-outline-danger ml-2'}>{list?.is_active == 1 ? "Active" : "Inactive"}</button>
          </td>
      </tr>
      )
     })

     :

     <tr>
      <td colSpan="4" className='text-center'></td>
     </tr>

  }
   
  </tbody>
</table>
:
<TableLoader/>

}
</div>


</div>
</div>
<Modal
  show={statusmodal}
  backdrop="static"
  keyboard={false}
  centered
>

  <Modal.Body className='text-center'>
      Are you sure you want to {status == 1 ? "Inactive" : "Active"}
      <ul className='d-flex mt-4 justify-content-center'>
          <li>
              <button className='btn btn-success mr-2' onClick={StatusHandler}>Yes</button>
          </li>
          <li>
              <button className='btn btn-outline-danger ml-2' onClick={()=>setstatusmodal(false)}>Cancel</button>
          </li>
      </ul>
  </Modal.Body>

</Modal> 
<Modal
  show={deletemodal}
  backdrop="static"
  keyboard={false}
  centered
>

  <Modal.Body className='text-center'>
      Are you sure you want to delete
      <ul className='d-flex mt-4 justify-content-center'>
          <li>
              <button className='btn btn-success mr-2' onClick={DeleteHandler}>Yes</button>
          </li>
          <li>
              <button className='btn btn-outline-danger ml-2' onClick={()=>setdeletemodal(false)}>Cancel</button>
          </li>
      </ul>
  </Modal.Body>

</Modal>
</div>
  )
}

export default CmsPage