import React from 'react'

const DashboardFooter = () => {
  return (
    <div className='copyright'>
        Copyright © {new Date().getFullYear()} FCC11. All Rights Reserve.
    </div>
  )
}

export default DashboardFooter