var url = window.location.origin + "/images/"

export const URL = {
   //front_url:'http://localhost:3000' ,
   front_url:'https://fancric11.com' ,
}

export const IMAGE = {
    main_logo:url + 'main-logo.png',
   banner: url + 'banner.png',
   banner_vector_one: url + 'banner-vector-man.png',
   banner_vector_cir: url + 'banner-vector-circle.png',
   step_one: url + 'step-one.png',
   step_two: url + 'step-two.png',
   step_three: url + 'step-three.png',
   step_four: url + 'step-four.png',
   about_img: url + 'about-img.png',
   intro_img: url + 'intro-img.png',
   cricketImg: url + 'cricket.png',
   icon_one: url + 'icon-three.png',
   icon_two: url + 'icon-one.png',
   icon_three: url + 'icon-two.png',
   partners_one: url + 'partners-1.png',
   partners_two: url + 'partners-2.png',
   partners_three: url + 'partners-3.png',
   partners_four: url + 'partners-4.png',
   partners_five: url + 'partners-5.png',
   video_icon: url + 'video-icon.png',
   footer_logo: url + 'footer-logo.png',
   footer_icon: url + 'footer-icon.png',
   clientImg: url + 'client.png',
   auth_vector: url + 'auth-vector.png',
   darhboard_alt: url + 'darhboard_alt.png',
   Chat_plus_light: url + 'Chat_plus_light.png',
   Calendar_add_light: url + 'Calendar_add_light.png',
   File_dock_light: url + 'File_dock_light.png',
   credit_icon: url + 'credit_icon.png',
   add_icon: url + 'add_icon.png',
   ind: url + 'ind.png',
   sa: url + 'sa.png',
   cup: url + 'cup.png',
   teamBg: url + 'createTeambg.png',
   player_1: url + 'player-1.png',
   sortIcon: url + 'ArrowsLeftRight.png',
   copy: url + 'copy.png',
   edit: url + 'edit-1.png',
   long_arrow: url + 'long-arrow.png',
   player_virat: url + 'player-virat.png',
   player_sreyas: url + 'player-sreyas.png',
   default: url + 'default.png',
   delete: url + 'delete.png',
   view: url + 'view.png',
   succes_image: url + 'succes_image.png',
}

export const COLORS = {
    primaryColor:'#299a22',
    secondaryColor:'#0842a9',
    thirdColor:'#ffd740',
}