import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import fccDataflowreducer from './reducer/fccDataflowreducer';

const persistConfig = {
    key: 'root',
    storage,
  }

  const rootReducer = combineReducers({ 
    fccDataflowreducer: fccDataflowreducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const Store = configureStore({
  reducer: persistedReducer,
})

export const persistor = persistStore(Store)